import React, { useState, useCallback, useRef, useEffect } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import config from '../../lib/config';
import InputBox from '../InputBox';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../contexts/LoadingContent';
import { useMapbox } from '../../utils/mapboxUtils';
import ModalComponent from '../ModalComponent';
import { useNotification } from '../../contexts/NotificationContext';
import 'mapbox-gl/dist/mapbox-gl.css';
// import { useCart } from '../../contexts/CartContent';
import { Address } from '../../types/dataType';
import blackBack from '../../assets/icons/blackBack.svg';
import gpsIcon from '../../assets/icons/gps.svg';
import * as _ from 'lodash';



type Viewport = {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing?: number;
  pitch?: number;
};

interface MapPickerModalProps {
  modal: boolean;
  setModal: (value: boolean) => void;
  setLatitude?: (value: number | null) => void;
  setLongitude?: (value: number | null) => void;
}

const MapPickerModal: React.FC<MapPickerModalProps> = ({ setModal, modal, setLatitude, setLongitude }) => {
  const { reverseGeocode, getMapKey } = useMapbox();
  const mapRef = useRef<any>(null);
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [mapkey, setMapKey] = useState<any>('');
  const [selectedPlace, setSelectedPlace] = useState<Address | undefined>(undefined);
  const { showLoading, hideLoading } = useLoading() || {};
  const { showNotification } = useNotification();
  // const { pickAddress } = useCart();
  const [markerPosition, setMarkerPosition] = useState<{ latitude: number; longitude: number }>({
    latitude: 1.5597515506061093,
    longitude: 110.34534195978594,
  });
  const [viewport, setViewport] = useState<Viewport>({
    latitude: 1.5597515506061093,
    longitude: 110.34534195978594,
    zoom: 17,
  });



  useEffect(() => {
    const fetchMapKey = async () => {
      try {
        const key = await getMapKey(); // Await the async function
        if (key) {
          setMapKey(key); // Store the key in state
        } else {
          console.error("Mapbox key is undefined or invalid.");
        }
      } catch (error) {
        console.error("Error fetching Mapbox key:", error);
      }
    };

    fetchMapKey();
    getCurrentLocation();
  }, []);


  const getCurrentLocation = () => {
    showLoading?.();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMarkerPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setViewport({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            zoom: 17,
          });
          setLocation(position.coords.latitude, position.coords.longitude)
          hideLoading?.();
        },
        (error) => {
          hideLoading?.();
          if (error.code === error.PERMISSION_DENIED) {
            showNotification('Location access is denied. Please allow location access in your Phone settings.', 'error');
          } else {
            console.error('Geolocation error:', error.message);
            showNotification('Unable to retrieve location. Please ensure you have a stable network connection and GPS signal.', 'error');
          }
        }
      );
    } else {
      hideLoading?.();
      showNotification('Geolocation is not supported by this browser.', 'error');
      // console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleMoveStart = useCallback(() => {
    setIsAnimating(true);
  }, []);

  const handleMoveEnd = useCallback(async (evt: any) => {
    const { latitude, longitude } = evt.viewState;
    setIsAnimating(false);
    setLocation(latitude, longitude)
  }, []);

  const onMove = useCallback((evt: any) => {
    const { latitude, longitude } = evt.viewState;

    setViewport(evt.viewState);
    setMarkerPosition({ latitude, longitude });

  }, []);

  const handleMapClick = useCallback((event: any) => {
    const { lngLat } = event;
    const { lng, lat } = lngLat;

    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: viewport.zoom,
      });
    }

    setViewport((prevViewport) => ({
      ...prevViewport,
      latitude: lat,
      longitude: lng,
    }));

    setMarkerPosition({ latitude: lat, longitude: lng });
  }, [viewport.zoom]);

  const handleSearchChange = async (searchValue: string) => {
    setQuery(searchValue);
    if (searchValue.length > 2) {
      // const results = await searchAddress(searchValue);
      // console.log('results', results)
      // setPlaces(results.map((place: any) => ({ place_name: place.place_name, text: place.text, lat: place.center[0], long: place.center[1] })));
    } else {
      // setPlaces([]);
    }
  };

  const setLocation = async (lat: number, long: number) => {
    const result = await reverseGeocode(lat, long);
    // console.log('result', result);
    const currentLocation = {
      place_name: result,
      text: result,
      lat: lat,
      long: long
    };
    setSelectedPlace(currentLocation)
  }


  return (
    <ModalComponent modal={modal} setModal={setModal} fullHeight>
      {mapkey && (
        <MapGL
          {...viewport}
          style={{ width: '100%', height: '100%' }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={mapkey}
          onMove={onMove}
          onMoveStart={handleMoveStart}
          onMoveEnd={handleMoveEnd}
          onClick={handleMapClick}
          ref={mapRef}
        >
          <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, width: '100%', padding: 16 }}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              borderRadius: '100%', width: 34, height: 30, backgroundColor: 'white', marginRight: 8
            }} onClick={() => setModal(false)}>
              <img style={{ width: 16, height: 16 }} src={blackBack} />
            </div>
            <div style={{ width: '100%' }} onClick={() => setModal(false)}>
              <InputBox deleteIcon locationIcon inputValue={query} text={t('Delivery To')} background={'white'}
                setInputValue={handleSearchChange} />
            </div>
          </div>
          {/* <NavigationControl style={{ position: 'absolute', right: 10, bottom: 160 }} /> */}
          {markerPosition && (
            <Marker
              latitude={markerPosition.latitude}
              longitude={markerPosition.longitude}
            >
              <div
                style={{
                  fontSize: 20,
                  transform: `translate(-50%, ${isAnimating ? '-20px' : '0'}) scale(${isAnimating ? 1.4 : 1})`,
                  transition: 'transform 0.3s',
                  pointerEvents: 'none',
                }}
              >
                📍
              </div>
            </Marker>
          )}
          <div
            style={{
              position: 'absolute',
              right: 10,
              bottom: 180,
              backgroundColor: '#fff',
              border: 'none',
              borderRadius: '50%',
              padding: 10,
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
            }}
            onClick={() => getCurrentLocation()}
          >
            <img src={gpsIcon} style={{ width: 20, height: 'auto' }} />
          </div>

          <div style={{
            position: 'absolute',
            width: '100%',
            bottom: 0,
          }} >
            <div style={{
              backgroundColor: '#fff',
              border: 'none',
              padding: 18,
              cursor: 'pointer',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              color: 'black'
            }}>
              {selectedPlace?.place_name}
            </div>
            <div style={{
              backgroundColor: '#fff',
              border: 'none',
              padding: 18,
              paddingBottom: 32,
              cursor: 'pointer',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            }} onClick={() => {
              if (selectedPlace) {
                setLongitude?.(selectedPlace.long ?? null);
                setLatitude?.(selectedPlace.lat ?? null);
                // pickAddress(selectedPlace)
                setModal(false)
              }
            }}>
              <div style={{
                padding: 12, width: '100%', backgroundColor: 'green',
                color: 'white', textAlign: 'center', borderRadius: 20,
                fontSize: 14, fontWeight: 500
              }}>
                {t('Choose This Location')}
              </div>
            </div>
          </div>
        </MapGL>
      )}
    </ModalComponent>
  );
};

export default MapPickerModal;