import { Navigate, Route } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContent';
import { ReactNode } from 'react';


interface ProtectedRouteProps {
  restricted: boolean;
  element: ReactNode;
}

const ProtectedRoute = ({ element, restricted }: ProtectedRouteProps) => {
  const { isAuthenticated } = useAuth();

  if (restricted && isAuthenticated) {
    return <Navigate to="/home" />;
  }

  // if (!restricted && !isAuthenticated) {
  //   // Redirect to login if user is not authenticated
  //   return <Navigate to="/login" />;
  // }

  return element;
};

export default ProtectedRoute;
