import React from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../services/firebase";
import { useNotification } from '../../contexts/NotificationContext';
import './index.scss';
import googleIcon from '../../assets/icons/google.svg';
import { useTranslation } from 'react-i18next';

const GoogleLoginComponent: React.FC = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      showNotification('Welcome, ' + user.email, 'success')
      console.log("User Info:", user);
    } catch (error) {
      console.error("Google Login Error:", error);
      showNotification("Login failed. Please try again.", 'error');
    }
  };


  return (
    <div onClick={handleGoogleLogin} className="google-login-button">
      <img style={{ width: 20, height: 20, marginRight: 8 }} src={googleIcon} />
      {t('Continue with Google')}
    </div>
  );
};

export default GoogleLoginComponent;