import { gql } from '@apollo/client';

export const USER_ADDRESS_BY_ID = gql`
  query UserAddress($input: UserAddressInput!) {
    userAddress(input: $input) {
      result {
        _id
        user_id
        is_default
        address_name
        address_details
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;