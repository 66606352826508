import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useLoading } from './LoadingContent';
import { FIREBASE_SIGN_IN } from '../query/signIn'
import { EDIT_PROFILE } from '../query/editProfile'
import { useApolloClient, ApolloClient, ApolloError } from '@apollo/client';
import { UserProfile, UserType } from '../types/dataType';
import { useNotification } from '../contexts/NotificationContext';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { auth } from "../services/firebase";
import { onAuthStateChanged, User, signOut } from "firebase/auth";
import createApolloClient from "../lib/apollo";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserType | null;
  isLoad: boolean;
  setUser: (user: UserType | null) => void;
  userSignOut: () => void;
  checkAuthentication: (currentUser?: User | null) => void;
  editProfile: (profileData: UserProfile) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [user, setUser] = useState<UserType | null>(null);
  const { showLoading, hideLoading } = useLoading() || {};
  const apolloClient: ApolloClient<any> = useApolloClient();
  const navigate = useNavigate();
  const { showNotification } = useNotification();


  const checkAuthentication = async (currentUser?: User | null) => {
    // let userData: UserType | null = null;]
    // console.log('currentUser', currentUser)
    try {
      if (currentUser) {
        showLoading?.();
        const newData = {
          idToken: await currentUser.getIdToken()
        }
        let result = await apolloClient.mutate({
          mutation: FIREBASE_SIGN_IN,
          variables: { input: newData },
        })
        console.log('FIREBASE_SIGN_IN res', result)
        if (result?.data) {
          if (result?.data?.firebaseSignIn?.result) {
            const loggedInUser = result.data.firebaseSignIn.result;
            Cookies.set('user', JSON.stringify(loggedInUser), { expires: 365 * 10, secure: true, sameSite: 'Strict' });
            setIsAuthenticated(true);
            // setUser(loggedInUser);
            hideLoading?.();
            setIsLoad(true);
            // userData = loggedInUser;
            // console.log('done', loggedInUser)
          }
        }
      } else {
        setIsLoad(true);
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.log('FIREBASE_SIGN_IN error', error)
      if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
        showNotification(error.graphQLErrors[0].message, 'error');
      } else {
        showNotification((error as Error)?.message ?? 'Something went wrong.', 'error');
      }
      hideLoading?.();
      setIsLoad(true);
      setIsAuthenticated(false);
      setUser(null);

    }
  };

  useEffect(() => {
    // console.log('auth', auth)
    const unsubscribe = onAuthStateChanged(auth, checkAuthentication);
    return unsubscribe;
  }, []);

  const userSignOut = async () => {
    try {
      await logout();
      setIsAuthenticated(false);
      setUser(null);
      Cookies.remove('user');
      showNotification('Logout successful', 'success');
      navigate('/login');
    } catch (error) {
      // Handle logout error
      showNotification('Logout failed', 'error');
      console.error("Logout Error:", error)
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout Error:", error);
      // Re-throw the error to be handled by userSignOut
      throw error;
    }
  };

  const editProfile = async (profileData: UserProfile) => {
    try {
      showLoading?.();
      const newData = {
        name: profileData?.name,
        email: profileData?.email,
        mobile_phone: profileData?.mobile_phone,
      }
      const apolloClient = createApolloClient(user?.auth_token ?? undefined);
      const result = await apolloClient.mutate({
        mutation: EDIT_PROFILE,
        variables: { input: newData },
      });
      if (result?.data) {
        // const updatedUser = result.data.editProfile.result;
        // Cookies.set('user', JSON.stringify(updatedUser), { expires: 365 * 10, secure: true, sameSite: 'Strict' });
        // setUser(updatedUser);
        showNotification('Profile updated successfully', 'success');
        hideLoading?.();
      }
    } catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
        showNotification(error.graphQLErrors[0].message, 'error');
      } else {
        showNotification((error as Error)?.message ?? 'Something went wrong.', 'error');
      }
      hideLoading?.();
      console.error('Error editProfile:', error);
    }
  }


  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoad, setIsAuthenticated, user, setUser, userSignOut, checkAuthentication, editProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};