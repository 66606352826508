import React from "react";
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { auth, facebookProvider } from "../../services/firebase";
import { useNotification } from '../../contexts/NotificationContext';
import './index.scss';
import facebookIcon from '../../assets/icons/facebook.svg';
import { useTranslation } from 'react-i18next';

const FacebookLoginComponent: React.FC = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const handleFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;

      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential?.accessToken;

      console.log("Facebook Access Token:", accessToken);

      showNotification('Welcome, ' + user.email, 'success')
      console.log("User Info:", user);
    } catch (error) {
      console.error("Facebook Login Error:", error);
      showNotification("Login failed. Please try again.", 'error');
    }
  };


  return (
    <div onClick={handleFacebookLogin} className="facebook-login-button">
      <img style={{ width: 20, height: 20, marginRight: 8 }} src={facebookIcon} />
      {t('Continue with Facebook')}
    </div>
  );
};

export default FacebookLoginComponent;