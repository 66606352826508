import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import LinkPath from '../../components/LinkPath';
import config from '../../lib/config';
import Header from '../../components/Header';
import AccountInputBox from '../../components/AccountInputBox';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../services/firebase";
import { useNotification } from '../../contexts/NotificationContext';
import { useLoading } from '../../contexts/LoadingContent';

const EmailLoginPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errors, setErrors] = useState<any>({});
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [loginData, setLoginData] = useState<any>({});
    const { showNotification } = useNotification();
    const { showLoading, hideLoading } = useLoading() || {};

    useEffect(() => {
        console.log('Player Data', loginData)
        const inputData = _.cloneDeep(errors);
        if (loginData?.usernameEmail === '' || !loginData?.usernameEmail) {
            _.set(inputData, 'usernameEmail', 'required');
        } else {
            _.unset(inputData, 'usernameEmail');
        }

        if ((loginData?.password === '' || !loginData?.password)) {
            _.set(inputData, 'password', 'required');
        } else {
            _.unset(inputData, 'password');
        }
        console.log('errors', inputData)
        setErrors(inputData);
    }, [loginData]);


    const onchangeData = (text: string, field: string) => {
        const inputData = _.cloneDeep(loginData);
        if (text === '') {
            _.unset(inputData, field);
        } else {
            _.set(inputData, field, text);
        }
        setLoginData(inputData);
    };


    const submit = async () => {
        setTriggerSubmit(true);
        if (_.isEmpty(errors)) {
            showLoading?.();
            try {
                const userCredential = await signInWithEmailAndPassword(auth, loginData.usernameEmail, loginData.password);
                console.log("User Info:", userCredential.user);
                showNotification('Welcome, ' + loginData.usernameEmail, 'success')
                hideLoading?.();
            } catch (error: any) {
                console.log("Login Error:", error);
                hideLoading?.();
                switch (error.code) {
                    case "auth/user-not-found":
                        showNotification("Invalid email or password.", 'error');
                        break;
                    case "auth/wrong-password":
                        showNotification("Invalid email or password.", 'error');
                        break;
                    case "auth/invalid-email":
                        showNotification("Invalid email or password.", 'error');
                        break;
                    case "auth/user-disabled":
                        showNotification("Account has been disabled, Please contact us.", 'error');
                        break;
                    default:
                        showNotification("Login failed. Please try again.", 'error');
                        break;
                }
            }
        }
    }



    return (
        <div className='phoneNumberContainer'>
            <Header back whiteTheme noBorder backAction={() => navigate('/login')} />
            <div className='phoneNumberContainer-body'>
                <div className='phoneNumberContainer-body-labelContainer'>
                    <div className='phoneNumberContainer-body-labelContainer-title'>{t('Log In')}</div>
                    <div className='phoneNumberContainer-body-labelContainer-desc'>{t('Let’s get started')}</div>
                </div>
                <div className='phoneNumberContainer-body-formContainer'>
                    <div className='phoneNumberContainer-body-formContainer-input'>
                        <AccountInputBox
                            label={'Username or email'}
                            inputValue={loginData?.usernameEmail ?? ''}
                            text={'Username or email'}
                            setInputValue={(e) => onchangeData(e, 'usernameEmail')}
                            triggerSubmit={triggerSubmit}
                            error={errors?.usernameEmail}
                        />
                    </div>
                    <div className='phoneNumberContainer-body-formContainer-input'>
                        <AccountInputBox
                            label={'Password'}
                            inputValue={loginData?.password ?? ''}
                            text={'Password'}
                            type={'password'}
                            setInputValue={(e) => onchangeData(e, 'password')}
                            triggerSubmit={triggerSubmit}
                            error={errors?.password}
                        />
                    </div>
                    <a className='phoneNumberContainer-body-forgetLink' onClick={() => navigate('/forgetPassword')}>{t('Forgot Password')}</a>
                </div>
                <div className='phoneNumberContainer-footer'>
                    <div className='phoneNumberContainer-footer-linkContainer'>
                        <div className='phoneNumberContainer-footer-linkContainer-text'>
                            {t('Not registered yet?')}
                        </div>
                        <a className='phoneNumberContainer-footer-linkContainer-link' onClick={() => navigate('/emailRegister')}>
                            {t('Sign Up')}
                        </a>
                    </div>
                    <Button text={'Log In'} action={() => {
                        submit()
                    }} />
                </div>
            </div>
        </div>
    );
};

export default EmailLoginPage;
