import React, { useState, useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import moreIcon from '../../assets/icons/more.svg';
import blackBack from '../../assets/icons/blackBack.svg';
import backIcon from '../../assets/icons/back.svg';
import blackSearch from '../../assets/icons/blackSearch.svg';
import searchIcon from '../../assets/icons/search.svg';
import blackInfo from '../../assets/icons/blackInfo.svg';
import infoIcon from '../../assets/icons/info.svg';
import languageIcon from '../../assets/icons/language.svg';

interface HeaderProps {
    title?: string;
    back?: boolean;
    info?: boolean;
    more?: boolean;
    search?: boolean;
    language?: boolean;
    searhAction?: () => void;
    infoAction?: () => void;
    moreAction?: () => void;
    backAction?: () => void;
    languageAction?: () => void;
    whiteTheme?: boolean;
    scrollWithColor?: boolean;
    noBorder?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, back, search, info, more, scrollWithColor, language,
    searhAction, moreAction, infoAction, backAction, languageAction,
    whiteTheme, noBorder }) => {
    const { t } = useTranslation();
    const [isScrolled, setIsScrolled] = useState(whiteTheme);


    useEffect(() => {
        if (scrollWithColor) {
            const handleScroll = () => {
                const scrollPosition = window.scrollY;
                const threshold = document.documentElement.scrollHeight * 0.02;
                if (scrollPosition > threshold) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollWithColor]);

    return (
        <div className={`headerContainer`}>
            <div className={`headerContainer-inner ${isScrolled ? 'scrolled' : ''} ${noBorder ? 'noBorder' : ''}`}>
                {back && (
                    <div className='headerContainer-inner-start' onClick={() => backAction?.()}>
                        {isScrolled ? (
                            <img src={blackBack} />
                        ) : (
                            <img src={backIcon} />
                        )}

                    </div>
                )}
                <div className={`headerContainer-inner-center ${isScrolled ? 'scrolled' : ''}`}>
                    {title && t(`${title}`)}
                </div>
                {search && (
                    <div className={`headerContainer-inner-end ${info ? 'pad' : ''}`} onClick={() => searhAction?.()}>
                        {isScrolled ? (
                            <img src={blackSearch} />
                        ) : (
                            <img src={searchIcon} />
                        )}
                    </div>
                )}

                {info && (
                    <div className='headerContainer-inner-end' onClick={() => { infoAction?.() }}>
                        {isScrolled ? (
                            <img src={blackInfo} />
                        ) : (
                            <img src={infoIcon} />
                        )}
                    </div>
                )}

                {more && (
                    <div className='headerContainer-inner-end' onClick={() => moreAction?.()}>
                        <img src={moreIcon} />
                    </div>
                )}
                {language && (
                    <div className='headerContainer-inner-end' onClick={() => languageAction?.()}>
                        <div className='headerContainer-inner-end-btn'>
                            <img src={languageIcon} />
                            <div className='headerContainer-inner-end-btn-text'>{t('Language')}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

};

export default Header;
