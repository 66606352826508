import React, { useState, useCallback, useRef, useEffect } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import config from '../../lib/config';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../contexts/LoadingContent';
import { useNotification } from '../../contexts/NotificationContext';
import { useMapbox } from '../../utils/mapboxUtils';
import { UPDATE_ARDDESS } from '../../query/updateAddress'
import { Address } from '../../types/dataType';
import { useAuth } from '../../contexts/AuthContent';
import { USER_ADDRESS_BY_ID } from '../../query/userAddressById'
import blackBack from '../../assets/icons/blackBack.svg';
import './index.scss';
import Button from '../../components/Button';
import AccountInputBox from '../../components/AccountInputBox';
import * as _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import createApolloClient from "../../lib/apollo";
import { ApolloError } from '@apollo/client';
import Switch from "react-switch";
import SearchLocationModal from '../../components/SearchLocationModal';


type Viewport = {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing?: number;
  pitch?: number;
};

const EditAddressPage = () => {
  const { addressId } = useParams();
  const { reverseGeocode, getMapKey } = useMapbox();
  const mapRef = useRef<any>(null);
  const { showLoading, hideLoading } = useLoading() || {};
  const { showNotification } = useNotification();
  const [selectedPlace, setSelectedPlace] = useState<Address | undefined>(undefined);
  const [mapkey, setMapKey] = useState<any>('');
  const [addressDetail, setAddressDetail] = useState<string | null>('');
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [defaultAddressDetail, setDefaultAddressDetail] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [markerPosition, setMarkerPosition] = useState<{ latitude: number; longitude: number }>({
    latitude: 1.5597515506061093,
    longitude: 110.34534195978594,
  });
  const [viewport, setViewport] = useState<Viewport>({
    latitude: 1.5597515506061093,
    longitude: 110.34534195978594,
    zoom: 20,
  });


  useEffect(() => {
    const fetchMapKey = async () => {
      try {
        const key = await getMapKey(); // Await the async function
        if (key) {
          console.log('key', key)
          setMapKey(key); // Store the key in state
        } else {
          console.error("Mapbox key is undefined or invalid.");
        }
      } catch (error) {
        console.error("Error fetching Mapbox key:", error);
      }
    };

    fetchMapKey();
  }, []);

  useEffect(() => {
    if (user) {
      getAddressDetailById();
    }
  }, [user]);

  const getAddressDetailById = async () => {
    try {
      showLoading?.();
      if (user) {
        const apolloClient = createApolloClient(user?.auth_token ?? undefined);
        const result = await apolloClient.query({
          query: USER_ADDRESS_BY_ID,
          variables: {
            input: {
              _id: addressId
            }
          },
        });
        // console.log('get user address', result);
        if (result?.data?.userAddress?.result) {
          hideLoading?.();
          setMarkerPosition({
            latitude: result?.data?.userAddress?.result?.coordinates?.latitude,
            longitude: result?.data?.userAddress?.result?.coordinates?.longitude,
          });
          setViewport({
            latitude: result?.data?.userAddress?.result?.coordinates?.latitude,
            longitude: result?.data?.userAddress?.result?.coordinates?.longitude,
            zoom: 18,
          });
          setLocation(result?.data?.userAddress?.result?.coordinates?.latitude, result?.data?.userAddress?.result?.coordinates?.longitude)
          setAddressDetail(result?.data?.userAddress?.result?.address_details);
          setDefaultAddressDetail(result?.data?.userAddress?.result?.is_default);

        } else {
          hideLoading?.();
          return [];
        }
      } else {
        hideLoading?.();
      }
    } catch (err) {
      hideLoading?.();
      if (err instanceof ApolloError && err.graphQLErrors.length > 0) {
        showNotification(err.graphQLErrors[0].message, 'error');
      } else {
        showNotification((err as Error)?.message ?? 'Something went wrong.', 'error');
      }
      console.log('get user address', err);
      return [];
    }
  }

  const setLocation = async (lat: number, long: number) => {
    console.log('lat', lat, 'long', long)
    const result = await reverseGeocode(lat, long);
    // console.log('result', result);
    const currentLocation = {
      place_name: result,
      text: result,
      lat: lat,
      long: long
    };
    setSelectedPlace(currentLocation)
  }

  useEffect(() => {
    if (latitude && longitude) {
      setMarkerPosition({
        latitude: latitude,
        longitude: longitude,
      });
      setViewport({
        latitude: latitude,
        longitude: longitude,
        zoom: 18,
      });
      setLocation(latitude, longitude);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    const inputData = _.cloneDeep(errors);
    if (selectedPlace === null || _.isEmpty(selectedPlace)) {
      _.set(inputData, 'selectedPlace', 'required');
    } else {
      _.unset(inputData, 'selectedPlace');
    }

    if ((addressDetail === '' || !addressDetail)) {
      _.set(inputData, 'addressDetail', 'required');
    } else {
      _.unset(inputData, 'addressDetail');
    }
    console.log('errors', inputData)
    setErrors(inputData);
  }, [addressDetail, selectedPlace]);

  const updateUserAddress = async () => {
    setTriggerSubmit(true);
    showLoading?.();
    if (_.isEmpty(errors) && user) {
      try {
        const newData = {
          _id: addressId,
          is_default: defaultAddressDetail,
          coordinates: {
            latitude: selectedPlace?.lat,
            longitude: selectedPlace?.long
          },
          address_name: selectedPlace?.place_name,
          address_details: addressDetail,
        }
        const apolloClient = createApolloClient(user?.auth_token ?? undefined);
        const result = await apolloClient.mutate({
          mutation: UPDATE_ARDDESS,
          variables: { input: newData },
        });
        if (result?.data) {
          showNotification('Add Address successfully', 'success');
          navigate(-1)
          hideLoading?.();
        }
      } catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
          showNotification(error.graphQLErrors[0].message, 'error');
        } else {
          showNotification((error as Error)?.message ?? 'Something went wrong.', 'error');
        }
        hideLoading?.();
        console.error('Error Add Address:', error);
      }
    } else {
      hideLoading?.();
    }
  }



  return (
    <div className='editAddressPage'>
      {mapkey && (
        <MapGL
          {...viewport}
          style={{ width: '100%', height: 200 }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={mapkey}
          attributionControl={false}
          logoPosition="top-right"
          ref={mapRef}
        >
          <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, width: '100%', padding: 16 }}>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              borderRadius: '100%', width: 34, height: 34, backgroundColor: 'white', marginRight: 8,
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
            }} onClick={() => navigate(-1)}>
              <img style={{ width: 16, height: 16 }} src={blackBack} />
            </div>
          </div>
          {markerPosition && (
            <Marker
              latitude={markerPosition.latitude}
              longitude={markerPosition.longitude}
            >
              <div
                style={{
                  fontSize: 20,
                  pointerEvents: 'none',
                }}
              >
                📍
              </div>
            </Marker>
          )}
        </MapGL>
      )}
      <div className='editAddressPage-modal'>
        <div className='editAddressPage-modal-label'>
          {t('Edit location details')}
        </div>
        <div className='editAddressPage-modal-body'>
          <div>
            <div className='editAddressPage-modal-body-label'>
              {t('Address')} <div className='editAddressPage-modal-body-label-require'>*</div>
            </div>
            <div className='editAddressPage-modal-body-currentAddress' onClick={() => setModal(true)}>
              <div className='editAddressPage-modal-body-currentAddress-logo'>
                <img src={require('../../assets/images/marker.png')} />
              </div>
              <div className='editAddressPage-modal-body-currentAddress-detail'>
                {selectedPlace?.place_name}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 8 }}>
            <div className='editAddressPage-modal-body-label'>
              {t('Address details')}
            </div>
            <AccountInputBox
              inputValue={addressDetail ?? ''}
              text={'e.g. Floor, unit number'}
              setInputValue={(e) => { setAddressDetail(e) }}
              triggerSubmit={triggerSubmit}
              error={errors?.addressDetail} />
          </div>
          <div className='editAddressPage-content-switch'>
            <div className='editAddressPage-content-switch-label'>
              {t('Set as Default Address')}
            </div>
            <div className='editAddressPage-content-switch-switch'>
              <Switch
                onColor={'#FFA500'}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => { setDefaultAddressDetail(!defaultAddressDetail) }} checked={defaultAddressDetail} />
            </div>
          </div>


        </div>
        <div className='editAddressPage-modal-bottom'>
          <div className='editAddressPage-modal-bottom-btn'>
            <Button text={`Edit`} action={() => {
              updateUserAddress();
            }} />
          </div>
        </div>
      </div>
      <SearchLocationModal setModal={setModal} modal={modal} setLatitude={setLatitude} setLongitude={setLongitude} />
    </div>
  );
};

export default EditAddressPage;
