import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import AccountInputBox from "../../components/AccountInputBox";
import Button from "../../components/Button";
import { useAuth } from "../../contexts/AuthContent";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../services/firebase";

const InputPhoneNumberPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user } = useAuth();
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

    // const setupRecaptcha = () => {
    //     if (!recaptchaVerifier) {
    //         const verifier = new RecaptchaVerifier(
    //             "recaptcha-container",
    //             { size: "invisible" },
    //             auth
    //         );
    //         verifier.render();
    //         setRecaptchaVerifier(verifier);
    //     }
    // };

    const sendOTP = async () => {
        // try {
        //     // setupRecaptcha();

        //     // if (!recaptchaVerifier) throw new Error("RecaptchaVerifier not initialized");

        //     const confirmationResult = await signInWithPhoneNumber(
        //         auth,
        //         `+6${user?.mobile_phone}`, // Dynamic phone number
        //     );

        //     console.log("OTP Sent Successfully!", confirmationResult);
        // } catch (error) {
        //     console.error("Failed to send OTP:", error);
        // }
        navigate('/otpVerification')
    };

    return (
        <div className="inputPhoneNumberContainer">
            <Header back whiteTheme noBorder backAction={() => navigate(-1)} />
            <div className="inputPhoneNumberContainer-body">
                <div className="inputPhoneNumberContainer-body-labelContainer">
                    <div className="inputPhoneNumberContainer-body-labelContainer-title">
                        {t("Verify your mobile number")}
                    </div>
                    <div className="inputPhoneNumberContainer-body-labelContainer-desc">
                        {t("We’ll send you a verification code.")}
                    </div>
                </div>
                <div className="inputPhoneNumberContainer-body-formContainer">
                    {user?.mobile_phone && (
                        <div className="inputPhoneNumberContainer-body-formContainer-input">
                            <AccountInputBox
                                label={"Mobile number"}
                                inputValue={`+6${user?.mobile_phone}`}
                                text={"Your mobile number"}
                                type="text"
                                setInputValue={() => { }}
                                disable
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="inputPhoneNumberContainer-footer">
                {user?.mobile_phone && <Button text="Get OTP" action={sendOTP} />}
            </div>
            {/* Ensure the div is present in the DOM */}
            <div id="recaptcha-container"></div>
        </div>
    );
};

export default InputPhoneNumberPage;