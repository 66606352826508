import { gql } from '@apollo/client';

export const USER_ADDRESSES = gql`
  query UserAddresses {
    userAddresses {
      result {
        _id
        user_id
        is_default
        address_name
        address_details
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;