import axios from 'axios';
import config from '../lib/config';
import { useApolloClient, ApolloError } from '@apollo/client';
import { GET_MAP_BOX } from '../query/getMapBoxKey';

const MAPBOX_API_URL = config?.MAPBOX_API_URL;

export const useMapbox = () => {
  const apolloClient = useApolloClient();


  const getMapKey = async () => {
    try {
      const result = await apolloClient.query({
        query: GET_MAP_BOX,
      });

      if (result?.data?.consumeAccessToken?.result) {
        return result?.data?.consumeAccessToken?.result?.access_token;
      } else {
        return 'Error';
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error';
    }
  };

  const reverseGeocode = async (lat: number, lng: number): Promise<string> => {
    try {

      const mapKey = await getMapKey();
      if (mapKey !== 'Error') {
        const response = await axios.get(`${MAPBOX_API_URL}/${lng},${lat}.json`, {
          params: {
            access_token: mapKey,
            limit: 1,
          },
        });

        return response.data.features[0]?.place_name || 'Address not found';
      } else {
        return 'Address not found';
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Address not found';
    }
  };

  const searchAddress = async (query: string) => {
    try {
      const mapKey = await getMapKey();
      if (mapKey !== 'Error') {
        const response = await axios.get(`${MAPBOX_API_URL}/${encodeURIComponent(query)}.json`, {
          params: {
            access_token: mapKey,
            autocomplete: true,
            limit: 8,
          },
        });

        return response.data.features;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching address data from Mapbox:', error);
      return [];
    }
  };

  return { reverseGeocode, searchAddress, getMapKey };
};