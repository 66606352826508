import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Header from '../../components/Header';
import OrderHistoryItem from '../../components/OrderHistoryItem';
import { Address } from '../../types/dataType';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import Button from '../../components/Button';
import AddressItem from '../../components/AddressItem';
import { SAVE_ARDDESS } from '../../query/addAddress'
import AddDeliveryComponent from '../../components/AddDeliveryComponent';
import AccountInputBox from '../../components/AccountInputBox';
import createApolloClient from "../../lib/apollo";
import { useAuth } from '../../contexts/AuthContent';
import { useNotification } from '../../contexts/NotificationContext';
import { useLoading } from '../../contexts/LoadingContent';
import Switch from "react-switch";
import dayjs from 'dayjs';

const AddAddressPage = () => {
  const { t } = useTranslation();
  // const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]);
  const navigate = useNavigate();
  const [addressDetail, setAddressDetail] = useState<string | null>('');
  const [defaultAddressDetail, setDefaultAddressDetail] = useState<boolean>(false);
  const [addressData, setAddressData] = useState<Address | null>();
  const { showLoading, hideLoading } = useLoading() || {};
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>({});
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const { user } = useAuth();


  useEffect(() => {
    const inputData = _.cloneDeep(errors);
    if (addressData === null || _.isEmpty(addressData)) {
      _.set(inputData, 'addressData', 'required');
    } else {
      _.unset(inputData, 'addressData');
    }

    if ((addressDetail === '' || !addressDetail)) {
      _.set(inputData, 'addressDetail', 'required');
    } else {
      _.unset(inputData, 'addressDetail');
    }
    console.log('errors', inputData)
    setErrors(inputData);
  }, [addressDetail, addressData]);

  const saveUserAddress = async () => {
    setTriggerSubmit(true);
    showLoading?.();
    if (_.isEmpty(errors) && user) {
      try {
        const newData = {
          is_default: defaultAddressDetail,
          coordinates: {
            latitude: addressData?.lat,
            longitude: addressData?.long
          },
          address_name: addressData?.place_name,
          address_details: addressDetail,
        }
        const apolloClient = createApolloClient(user?.auth_token ?? undefined);
        const result = await apolloClient.mutate({
          mutation: SAVE_ARDDESS,
          variables: { input: newData },
        });
        if (result?.data) {
          showNotification('Add Address successfully', 'success');
          navigate(-1)
          hideLoading?.();
        }
      } catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
          showNotification(error.graphQLErrors[0].message, 'error');
        } else {
          showNotification((error as Error)?.message ?? 'Something went wrong.', 'error');
        }
        hideLoading?.();
        console.error('Error Add Address:', error);
      }
    } else {
      hideLoading?.();
    }
  }

  return (
    <div className='addAddressManagementPage'>
      <Header back title={t('Add Address')} whiteTheme backAction={() => { navigate(-1) }} />
      <div className='addAddressManagementPage-content'>
        <div className='addAddressManagementPage-content-header'>
          <div className='addAddressManagementPage-content-header-label'>
            {t('Address Information')}
          </div>
        </div>
        <AddDeliveryComponent setSaveAddressData={setAddressData} />
        <div style={{ marginTop: 8 }}>
          <div className='addAddressManagementPage-content-formLabel'>
            {t('Address details')}
          </div>
          <AccountInputBox
            inputValue={addressDetail ?? ''}
            text={'e.g. Floor, unit number'}
            setInputValue={(e) => { setAddressDetail(e) }}
            triggerSubmit={triggerSubmit}
            error={errors?.addressDetail}
          />
        </div>
        <div className='addAddressManagementPage-content-switch'>
          <div className='addAddressManagementPage-content-switch-label'>
            {t('Set as Default Address')}
          </div>
          <div className='addAddressManagementPage-content-switch-switch'>
            <Switch
              onColor={'#FFA500'}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => { setDefaultAddressDetail(!defaultAddressDetail) }} checked={defaultAddressDetail} />
          </div>
        </div>
      </div>
      <div className='addAddressManagementPage-footer'>
        <div className='addAddressManagementPage-footer-btn'>
          <Button text={`Save Changes`} action={() => {
            saveUserAddress()
          }} />
        </div>
      </div>
    </div >
  );
};

export default AddAddressPage;
