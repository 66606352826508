import React from "react";
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import { auth, appleProvider } from "../../services/firebase";
import { useNotification } from '../../contexts/NotificationContext';
import './index.scss';
import appleIcon from '../../assets/icons/apple.svg';
import { useTranslation } from 'react-i18next';

const AppleLoginComponent: React.FC = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const handleAppleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      const user = result.user;

      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential?.accessToken;
      const idToken = credential?.idToken;

      console.log("Apple Access Token:", accessToken);
      console.log("Apple ID Token:", idToken);

      showNotification('Welcome, ' + user.email, 'success')
      console.log("User Info:", user);
    } catch (error) {
      console.error("Apple Login Error:", error);
      showNotification("Login failed. Please try again.", 'error');
    }
  };


  return (
    <div onClick={handleAppleLogin} className="apple-login-button">
      <img style={{ width: 20, height: 20, marginRight: 8 }} src={appleIcon} />
      {t('Continue with Apple')}
    </div>
  );
};

export default AppleLoginComponent;