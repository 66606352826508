import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import Button from '../../components/Button';
import QrScannerModal from '../../components/QrScannerModal';
import qrscan from '../../assets/icons/qrscan.svg';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const { t } = useTranslation();
    const [qrSacanner, setQrScanner] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const latest_merOrderNo = localStorage.getItem('latest_merOrderNo');
        if (latest_merOrderNo) {
            navigate(`/paymentStatus?merOrderNo=${latest_merOrderNo}`);
        }
    }, []);

    return (
        <div className='homeContainer'>
            <div className='homeContainer-topSection'>
                <img src={require('../../assets/images/logo.png')} />
                <div className='homeContainer-topSection-text'>BeReach</div>
            </div>
            <div className='homeContainer-centerSection mt-5'>
                <div className='homeContainer-centerSection-inner'>
                    <div className='homeContainer-centerSection-inner-text mb-3'>
                        {t('Good afternoon,')}
                    </div>
                    <img src={require('../../assets/images/rafiki.png')} />
                    <div className='homeContainer-centerSection-inner-text2 mt-3'>
                        {t('Ready to order?')}
                    </div>
                    <div className='homeContainer-centerSection-inner-text3 mt-2 mx-4'>
                        {t('Scan the restaurant QR code to view the menu and order.')}
                    </div>
                </div>
            </div>
            <div className='homeContainer-centerSection-inner-btn mt-4'>
                <Button text={'Scan QR code'} action={() => setQrScanner(true)} icon={qrscan} />
            </div>
            <QrScannerModal openCamera={qrSacanner} setOpenCamera={setQrScanner} />
        </div>
    );
};

export default HomePage;
