import { gql } from "@apollo/client";

export const UPDATE_ARDDESS = gql`
  mutation UpdateUserAddress($input: UpdateUserAddressInput!) {
    updateUserAddress(input: $input) {
      result {
        _id
        user_id
        is_default
        address_name
        address_details
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;