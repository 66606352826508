
import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.scss';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMapbox } from '../../utils/mapboxUtils';
import { useCart } from '../../contexts/CartContent';
import { useLoading } from '../../contexts/LoadingContent';
import { useNotification } from '../../contexts/NotificationContext';
import AddSearchLocationModal from '../AddSearchLocationModal';
import rightBlue from '../../assets/icons/rightBlue.svg';
import { Address } from '../../types/dataType';
import { useNavigate } from 'react-router-dom';

interface AddDeliveryComponentProps {
  setSaveAddressData: (value: Address) => void;
}


const AddDeliveryComponent: React.FC<AddDeliveryComponentProps> = ({ setSaveAddressData }) => {
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [modal, setModal] = useState<boolean>(false);
  // const { selectedAddress, pickAddress } = useCart();
  const { showNotification } = useNotification();
  const { showLoading, hideLoading } = useLoading() || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reverseGeocode } = useMapbox();
  const [addressData, setAddressData] = useState<Address | null>(null);

  useEffect(() => {
    getCurrentLocation();
  }, []);


  const getCurrentLocation = () => {
    showLoading?.();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          handleFindAddress(position.coords.latitude, position.coords.longitude);
          hideLoading?.();
        },
        (error) => {
          hideLoading?.();
          if (error.code === error.PERMISSION_DENIED) {
            showNotification('Location access is denied. Please allow location access in your Phone settings.', 'error');
          } else {
            console.error('Geolocation error:', error.message);
            showNotification('Unable to retrieve location. Please ensure you have a stable network connection and GPS signal.', 'error');
          }
          setLatitude(null);
          setLongitude(null);
        }
      );
    } else {
      hideLoading?.();
      showNotification('Geolocation is not supported by this browser.', 'error');
      // console.log("Geolocation is not supported by this browser.");
    }
  };

  const handleFindAddress = async (latitude: number, longitude: number) => {
    if (latitude !== 0 && longitude !== 0) {
      showLoading?.();
      const result = await reverseGeocode(latitude, longitude);
      // console.log('result', result);
      const currentLocation = {
        place_name: result,
        text: result,
        lat: latitude,
        long: longitude
      }
      setAddressData(currentLocation)
      setSaveAddressData(currentLocation)
      // pickAddress(currentLocation);
      hideLoading?.();
    }
  };




  return (
    <>
      <div className='addDeliveryContainer-currentAddress' onClick={() => setModal(true)}>
        <div className='addDeliveryContainer-currentAddress-logo'>
          <img src={require('../../assets/images/marker.png')} />
        </div>
        <div className='addDeliveryContainer-currentAddress-detail'>
          {addressData?.place_name}
        </div>
        <div className='addDeliveryContainer-currentAddress-more' >
          <img src={rightBlue} />
        </div>
      </div>
      <AddSearchLocationModal setModal={setModal} modal={modal} getCurrentLocation={getCurrentLocation} setAddressData={setAddressData} />
    </>
  )

};

export default AddDeliveryComponent;