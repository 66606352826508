import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import LoginButton from '../../components/LoginButton';
import { useNavigate } from 'react-router-dom';
import LinkPath from '../../components/LinkPath';
import config from '../../lib/config';
import Header from '../../components/Header';
import GoogleLoginComponent from '../../components/GoogleLoginComponent';
import mail from '../../assets/icons/mail.svg';
import AppleLoginComponent from '../../components/AppleLoginComponent';
import FacebookLoginComponent from '../../components/FacebookLoginComponent';


const LoginPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();


    return (
        <div className='loginContainer'>
            <Header back whiteTheme noBorder backAction={() => navigate(-1)} />
            <div className='loginContainer-body'>
                <div className='loginContainer-body-labelContainer'>
                    <div className='loginContainer-body-labelContainer-title'>{t('Log In')}</div>
                    <div className='loginContainer-body-labelContainer-desc'>{t('Let’s get started')}</div>
                </div>
                <div className='loginContainer-body-buttonContainer'>
                    <GoogleLoginComponent />
                </div>
                <div className='loginContainer-body-buttonContainer'>
                    <FacebookLoginComponent />
                </div>
                <div className='loginContainer-body-buttonContainer'>
                    <AppleLoginComponent />
                </div>

                <div className='loginContainer-body-middleContainer'>
                    <div className='loginContainer-body-middleContainer-line'></div>
                    <div className='loginContainer-body-middleContainer-text'>
                        {t('Or')}
                    </div>
                </div>
                <div className='loginContainer-body-buttonContainer'>
                    <LoginButton icon={mail} text='Continue with Email' action={() => navigate('/emailLogin')} />
                </div>
                <div className='loginContainer-body-bottomContainer'>
                    <LinkPath text={'Continue as guest'} action={() => navigate('/home')} />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
