import React, { useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import editAddressIcon from '../../assets/icons/editAddress.svg';
import deleteAddressIcon from '../../assets/icons/deleteAddress.svg';
import { SaveAddress } from '../../types/dataType';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../contexts/NotificationContext';
import { useLoading } from '../../contexts/LoadingContent';
import createApolloClient from "../../lib/apollo";
import { ApolloError } from '@apollo/client';
import { useAuth } from '../../contexts/AuthContent';
import { DELETE_ADDRESS } from '../../query/deleteAddress'

interface AddressItemProps {
  data?: SaveAddress;
  getUserAddressList?: () => void;
  user?: any;
}

// Functional component with props type
const AddressItem: React.FC<AddressItemProps> = ({
  data, getUserAddressList, user
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { showLoading, hideLoading } = useLoading() || {};
  const { showNotification } = useNotification();


  const alertContainerStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  };

  const alertBoxStyles: React.CSSProperties = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const buttonBoxStyles: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-around',
  };

  const yesbuttonStyles: React.CSSProperties = {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: 'orange',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    marginRight: '8px'
  };

  const nobuttonStyles: React.CSSProperties = {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: 'red',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    marginLeft: '8px'
  };

  const deleteAddress = () => {
    setShowAlert(true);
  };


  const deleteAddressData = async () => {
    setShowAlert(false);
    try {
      showLoading?.();
      const apolloClient = createApolloClient(user?.auth_token ?? undefined);
      const result = await apolloClient.mutate({
        mutation: DELETE_ADDRESS,
        variables: {
          input: {
            _id: data?._id
          }
        },
      });
      // console.log('delete user address', result);
      if (result?.data) {
        showNotification('Delete Address successfully', 'success');
        getUserAddressList?.();
        hideLoading?.();
      }
    } catch (err) {
      hideLoading?.();
      if (err instanceof ApolloError && err.graphQLErrors.length > 0) {
        showNotification(err.graphQLErrors[0].message, 'error');
      } else {
        showNotification((err as Error)?.message ?? 'Something went wrong.', 'error');
      }
      console.log('get user address', err);
      return [];
    }
  };


  return (
    <>
      {showAlert && (
        <div style={alertContainerStyles}>
          <div style={alertBoxStyles}>
            <p>{t('Are you sure you want to delete this address?')}</p>
            <div style={buttonBoxStyles}>
              <button onClick={() => { deleteAddressData() }} style={yesbuttonStyles}>
                {t('Yes')}
              </button>
              <button onClick={() => { setShowAlert(false) }} style={nobuttonStyles}>
                {t('No')}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='addressItem'>
        <div className='addressItem-left'>
          <div className='addressItem-left-top'>
            {t(`${data?.address_details}`)}
            {data?.is_default && (
              <div className={`addressItem-left-top-status completed`}>
                {t(`Default`)}
              </div>
            )}
          </div>
          <div className='addressItem-left-middle'>
            <div className='addressItem-left-middle-data'>
              {t(`${data?.address_name}`)}
            </div>
          </div>
        </div>
        <div className='addressItem-right'>
          <div onClick={() => navigate(`/editAddress/${data?._id}`)}>
            <img src={editAddressIcon} />
          </div>
          <div onClick={() => deleteAddress()}>
            <img src={deleteAddressIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressItem;
