import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
// Define the props interface
interface ButtonProps {
  backgroundColor?: string;
  color?: string;
  text: string;
  action: () => void;
  icon?: string;
}

// Functional component with props type
const LoginButton: React.FC<ButtonProps> = ({
  backgroundColor = '#190DA2',
  color = '#fff',
  text,
  action,
  icon,
}) => {
  const { t } = useTranslation();
  return (

    <div onClick={() => { action(); }}
      className='buttonContainer'
      style={{
        textAlign: 'center',
        backgroundColor: backgroundColor,
        color: color,
      }} >
      {icon && (<img style={{ width: 20, height: 20, marginRight: 8 }} src={icon} />)}
      {t(text)}
    </div>

  );
};

export default LoginButton;