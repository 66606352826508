import { gql } from '@apollo/client';

// Define the query with the necessary fields
export const GET_MAP_BOX = gql`
  query ConsumeAccessToken {
    consumeAccessToken {
      result {
        _id
        name
        access_token
        consumption_time
        consumption_limit_time
        updated_date
      }
    }
  }
`;
