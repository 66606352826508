import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../types/dataType';
import AccountInputBox from '../../components/AccountInputBox';
import { useNotification } from '../../contexts/NotificationContext';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { useAuth } from '../../contexts/AuthContent';


const EditAccountPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState({});
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [profileData, setProfileData] = useState<UserProfile>({});
  const { user, editProfile } = useAuth();

  useEffect(() => {
    if (user) {
      console.log('User Data', user)
      setProfileData(user);
    }
  }, [user]);

  const onchangeData = (text: string, field: string) => {
    const inputData = _.cloneDeep(profileData);
    if (text === '') {
      _.unset(inputData, field);
    } else {
      _.set(inputData, field, text);
    }
    setProfileData(inputData);
  };

  const editProfileData = () => {
    editProfile(profileData);
  }



  return (
    <div className='editAccountPage'>
      <Header back title={t('Profile')} whiteTheme backAction={() => { navigate('/account') }} />
      <div className='editAccountPage-topSection'>
        <div className='editAccountPage-topSection-avatar'>
          <img src={profileData?.avatar || require('../../assets/images/logo.png')} referrerPolicy="no-referrer" alt="Profile Avatar" />
        </div>
      </div>
      <div className='editAccountPage-bodySection'>
        <div className='editAccountPage-bodySection-inputBox'>
          <AccountInputBox
            label={'Name'}
            inputValue={profileData?.name ?? ''}
            text={'Name'}
            setInputValue={(e) => onchangeData(e, 'name')}
          />
        </div>
        <div className='editAccountPage-bodySection-inputBox'>
          <AccountInputBox
            label={'Email'}
            inputValue={profileData?.email ?? ''}
            text={'Email'}
            setInputValue={(e) => onchangeData(e, 'email')}
          />
        </div>
        <div className='editAccountPage-bodySection-inputBox'>
          <AccountInputBox
            label={'Mobile number'}
            inputValue={profileData?.mobile_phone ?? ''}
            text={'Mobile number'}
            setInputValue={(e) => onchangeData(e, 'mobile_phone')}
            error={profileData?.mobile_verified === false ? ' ' : ''}
            triggerSubmit={profileData?.mobile_verified === false ? true : false}
          />
          {!profileData?.mobile_verified && (
            <div className='editAccountPage-bodySection-inputBox-phone-verification' onClick={() => {
              showNotification('Coming soon', 'info')
              // navigate('/inputPhoneNumber')
            }}>
              {t('Verify now')}
            </div>
          )}
        </div>
        <div className='editAccountPage-bodySection-inputBox'>
          <a className='editAccountPage-bodySection-inputBox-forgetLink' onClick={() => navigate('/resetPassword')}>{t('Change Password')}</a>
        </div>

      </div>
      <div className='editAccountPage-footerSection'>
        <Button text={`Save Changes`} action={() => {
          editProfileData();
        }} />
      </div>
    </div>
  );
};

export default EditAccountPage;
