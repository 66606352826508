import { gql } from "@apollo/client";

export const EDIT_PROFILE = gql`
  mutation UpdateUserWithFirebaseConnect($input: UpdateUserWithFirebaseConnectInput!) {
    updateUserWithFirebaseConnect(input: $input) {
      result {
        _id
        name
        email
        avatar
        mobile_phone
        mobile_verified
        sign_in_provider_list {
          uid
          email
          provider
        }
      }
    }
  }
`;
