import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useApolloClient, ApolloClient, ApolloError } from '@apollo/client';
import { GET_ORDER_BY_ID } from '../query/getOrder';
import { GET_ORDER_LIST } from '../query/getUserOrderList';
import { Order, OrderHistory } from '../types/dataType';
import { useLoading } from './LoadingContent';
import { useNotification } from './NotificationContext';
import createApolloClient from "../lib/apollo";
import { useAuth } from './AuthContent';
import * as _ from 'lodash';

interface OrderContenttProps {
    getOrderDetails: (orderId: string | null) => Promise<Order | undefined>;
    getUserOrderList: () => Promise<OrderHistory[] | undefined>;
}

const OrderContent = createContext<OrderContenttProps | undefined>(undefined);

export const useOrder = () => {
    const context = useContext(OrderContent);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}


export const OrderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const apolloClient: ApolloClient<any> = useApolloClient();
    const { showLoading, hideLoading } = useLoading() || {};
    const { showNotification } = useNotification();
    const { user, isAuthenticated, isLoad } = useAuth();

    const getOrderDetails = async (billGroupNo: string | null): Promise<Order | undefined> => {
        if (billGroupNo) {
            // showLoading?.()
            // console.log('billGroupNo', billGroupNo)
            try {
                const result = await apolloClient.query({
                    query: GET_ORDER_BY_ID,
                    variables: { input: { bill_group_no: parseInt(billGroupNo, 10) } },
                });
                // console.log('getOrderDetails', result);

                if (result?.data?.orderInformation?.result) {
                    hideLoading?.();
                    return result.data?.orderInformation?.result;
                } else {
                    hideLoading?.();
                    return;
                }
            } catch (err) {
                hideLoading?.();
                if (err instanceof ApolloError && err.graphQLErrors.length > 0) {
                    showNotification(err.graphQLErrors[0].message, 'error');
                } else {
                    showNotification((err as Error)?.message ?? 'Something went wrong.', 'error');
                }
            }
        }
        return undefined;
    };

    const getUserOrderList = async () => {
        try {
            showLoading?.();
            if (isLoad) {
                if (isAuthenticated) {
                    const apolloClient = createApolloClient(user?.auth_token ?? undefined);
                    const result = await apolloClient.query({
                        query: GET_ORDER_LIST,
                    });
                    console.log('getUserOrderList', result);
                    if (result?.data?.orderInformationList?.result) {
                        hideLoading?.();
                        return result.data?.orderInformationList?.result?.map((item: any) => ({
                            company_name: item?.group_order_list?.[0]?.company?.display_name ?? "",
                            date: item?.group_order_list?.[0]?.created_date ?? "",
                            order_group_id: item?.bill_group_no ?? "",
                            payment_method: item?.group_order_list?.[0]?.payment_type ?? "",
                            total_amount: parseFloat(item?.group_net_price) || 0,
                        })) ?? [];
                    } else {
                        hideLoading?.();
                        return [];
                    }
                } else {
                    hideLoading?.();
                    const billHistory = localStorage.getItem('bill_history_list')
                    if (billHistory) {
                        const billHistoryJson = JSON.parse(billHistory);
                        const reversedArray = [...billHistoryJson].reverse();
                        return reversedArray;
                    }

                }
            } else {
                hideLoading?.();
                return [];
            }
        } catch (err) {
            hideLoading?.();
            if (err instanceof ApolloError && err.graphQLErrors.length > 0) {
                showNotification(err.graphQLErrors[0].message, 'error');
            } else {
                showNotification((err as Error)?.message ?? 'Something went wrong.', 'error');
            }
            console.log('getUserOrderList', err);
            return [];
        }
    }

    return (
        <OrderContent.Provider value={{ getOrderDetails, getUserOrderList }}>
            {children}
        </OrderContent.Provider>
    );
};
