import { gql } from '@apollo/client';

export const FIREBASE_SIGN_IN = gql`
  mutation FirebaseSignIn($input: FirebaseSignInInput!) {
    firebaseSignIn(input: $input) {
      result {
        _id
        name
        email
        avatar
        mobile_phone
        mobile_verified
        sign_in_provider_list  {
          uid
          email
          provider
        }
        auth_token
      }
    }
  }
`;
