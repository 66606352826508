import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import './index.scss';
import { useTranslation } from 'react-i18next';
import InputBox from '../InputBox';
import ModalComponent from '../ModalComponent';
import { useMapbox } from '../../utils/mapboxUtils';
import { Address } from '../../types/dataType';
import { useCart } from '../../contexts/CartContent';
import MapPickerModal from '../MapPickerModal';
import blackBack from '../../assets/icons/blackBack.svg';
import gpsIcon from '../../assets/icons/gps.svg';
import mapIcon from '../../assets/icons/map.svg';
import AddMapPickerModal from '../AddMapPickerModal';

interface SearchLocationModalProps {
  modal: boolean;
  setModal: (value: boolean) => void;
  getCurrentLocation: () => void;
  setAddressData: (value: Address) => void
}


const SearchLocationModal: React.FC<SearchLocationModalProps> = ({
  modal,
  setModal,
  getCurrentLocation,
  setAddressData
}) => {
  const { searchAddress } = useMapbox();
  const [query, setQuery] = useState<string>('');
  const [places, setPlaces] = useState<Address[]>([]);
  const [currentLocationBtn, setDisplayCurrentLocationBtn] = useState<boolean>(true);
  const [mapModal, setMapModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSearchChange = async (searchValue: string) => {
    setQuery(searchValue);
    if (searchValue.length > 2) {
      setDisplayCurrentLocationBtn(false)
      const results = await searchAddress(searchValue);
      // console.log('results', results)
      setPlaces(results.map((place: any) => ({ place_name: place.place_name, text: place.text, lat: place.center[0], long: place.center[1] })));
    } else {
      setPlaces([]);
      setDisplayCurrentLocationBtn(true)
    }
  };

  // useEffect(() => {
  //   if (selectedAddress) {
  //     setQuery(selectedAddress?.place_name)
  //   }
  // }, [selectedAddress]);


  return (
    <>
      <ModalComponent modal={modal} setModal={setModal} fullHeight>
        <div className='searchLocationModal-modal-header'>
          <div className='searchLocationModal-modal-header-inner'>
            <div className='searchLocationModal-modal-header-inner-icon' onClick={() => setModal(false)}>
              <img src={blackBack} />
            </div>
            <div className='searchLocationModal-modal-header-inner-input'>
              <InputBox deleteIcon locationIcon inputValue={query}
                setInputValue={handleSearchChange} />
            </div>
          </div>
        </div>
        <div className='searchLocationModal-modal-body'>
          {currentLocationBtn && (
            <div className='location-btn' onClick={() => {
              getCurrentLocation();
              setModal(false)
            }}>
              <div className='location-btn-left'>
                <img src={gpsIcon} />
              </div>
              <div className='location-btn-right'>
                <div className='location-btn-right-top'>
                  {t('Current location')}
                </div>
                <div className='location-btn-right-bottom'>
                  {/* {selectedAddress?.place_name} */}
                </div>
              </div>
            </div>
          )}
          {_.map(places, (address, index) => (
            <div key={index} className='location-result-item' onClick={() => {
              // pickAddress(address);
              setAddressData(address);
              setModal(false)
            }}>
              <div className='location-result-item-top'>
                {address?.text}
              </div>
              <div className='location-result-item-bottom'>
                {address?.place_name}
              </div>
            </div>
          ))}
        </div>
        <div className='searchLocationModal-modal-footer' onClick={() => {
          setModal(false)
          setMapModal(true)
        }}>
          <img src={mapIcon} />
          <div className='searchLocationModal-modal-footer-label'>
            {t('Choose from map')}
          </div>
        </div>
      </ModalComponent >
      <AddMapPickerModal modal={mapModal} setModal={setMapModal} setAddressData={setAddressData} />
    </>
  );
};

export default SearchLocationModal;
