import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Header from '../../components/Header';
import OrderHistoryItem from '../../components/OrderHistoryItem';
import { SaveAddress } from '../../types/dataType';
import { ApolloError } from '@apollo/client';
import { USER_ADDRESSES } from '../../query/userAddress'
import createApolloClient from "../../lib/apollo";
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import AddressItem from '../../components/AddressItem';
import { useNotification } from '../../contexts/NotificationContext';
import { useLoading } from '../../contexts/LoadingContent';
import { useAuth } from '../../contexts/AuthContent';

const AddressManagementPage = () => {
  const { t } = useTranslation();
  const [addressRecord, setAddressRecord] = useState<SaveAddress[]>([]);
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading() || {};
  const { showNotification } = useNotification();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      getUserAddressList();
    }
  }, [user]);


  const getUserAddressList = async () => {
    try {
      showLoading?.();
      if (user) {
        const apolloClient = createApolloClient(user?.auth_token ?? undefined);
        const result = await apolloClient.query({
          query: USER_ADDRESSES,
        });
        // console.log('get user address', result);
        if (result?.data?.userAddresses?.result) {
          hideLoading?.();
          setAddressRecord(result?.data?.userAddresses?.result);
        } else {
          hideLoading?.();
          return [];
        }
      } else {
        hideLoading?.();
        const billHistory = localStorage.getItem('bill_history_list')
        if (billHistory) {
          const billHistoryJson = JSON.parse(billHistory);
          const reversedArray = [...billHistoryJson].reverse();
          return reversedArray;
        }

      }
    } catch (err) {
      hideLoading?.();
      if (err instanceof ApolloError && err.graphQLErrors.length > 0) {
        showNotification(err.graphQLErrors[0].message, 'error');
      } else {
        showNotification((err as Error)?.message ?? 'Something went wrong.', 'error');
      }
      console.log('get user address', err);
      return [];
    }
  }


  return (
    <div className='addressManagementPage'>
      <Header back title={t('Manage Address')} whiteTheme backAction={() => { navigate('/account') }} />
      <div className='addressManagementPage-content'>
        {!_.isEmpty(addressRecord) ? (
          <>
            <div className='addressManagementPage-content-header'>
              <div className='addressManagementPage-content-header-label'>
                {t('Saved Address')}
              </div>
            </div>
            <div className='addressManagementPage-content-body'>
              {_.map(addressRecord, (item, index) => (
                <div key={index} >
                  <AddressItem data={item} getUserAddressList={getUserAddressList} user={user} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className='addressManagementPage-content-body'>
            <div className='addressManagementPage-content-empty'>
              <div className='addressManagementPage-content-empty-notFound'>
                <img src={require('../../assets/images/locationMarker.png')} />
                <div className='addressManagementPage-content-empty-title'>
                  {t('No address saved yet')}
                </div>
                <div className='addressManagementPage-content-empty-desc'>
                  {t('Please add a new address for your better experience.')}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='addressManagementPage-footer'>
        <div className='addressManagementPage-footer-btn'>
          <Button text={`Add New Address`} action={() => {
            navigate('/addAddress')
          }} />
        </div>
      </div>
    </div>
  );
};

export default AddressManagementPage;
