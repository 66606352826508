import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import OtpInput from 'react-otp-input';
import { useAuth } from "../../contexts/AuthContent";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

const OtpVerificationPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const { user } = useAuth();
    const [otp, setOtp] = useState('');
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [loginData, setLoginData] = useState<any>({});
    dayjs.extend(duration);

    const [sendMsg, setSendMsg] = useState(true);
    const [remainingTime, setRemainingTime] = useState(dayjs.duration(5, 'minutes'));
    const [resend, setResend] = useState(false);


    useEffect(() => {
        if (sendMsg) {
            const interval = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime.asSeconds() <= 0) {
                        clearInterval(interval);
                        setResend(true);
                        return dayjs.duration(0);
                    }
                    return dayjs.duration(prevTime.asSeconds() - 1, 'seconds');
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [sendMsg, resend]);

    const formatTime = () => {
        const minutes = remainingTime.minutes();
        const seconds = remainingTime.seconds();
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };



    return (
        <div className='otpVerificationContainer'>
            <Header back whiteTheme noBorder backAction={() => navigate(-1)} />
            <div className='otpVerificationContainer-body'>
                <div className='otpVerificationContainer-body-labelContainer'>
                    <div className='otpVerificationContainer-body-labelContainer-title'>{t('Let’s confirm it’s you')}</div>
                    <div className='otpVerificationContainer-body-labelContainer-desc'>{t('Enter the code we just sent to')}<strong> +6{user?.mobile_phone}</strong></div>
                </div>
                <div className='otpVerificationContainer-body-formContainer'>
                    <div className='otpVerificationContainer-body-formContainer-input'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputType="number"
                            shouldAutoFocus={true}
                            containerStyle={{ width: '100%', justifyContent: 'space-between' }}
                            inputStyle={{ width: '100%', height: '50px', fontSize: '20px', borderRadius: '10px', border: '1px solid #b7b7b7', outline: 'none' }}
                            renderSeparator={<span style={{ width: 32 }}></span>}

                            renderInput={(props) => <input  {...props} className='otpVerificationContainer-body-formContainer-input-box' />}
                        />
                    </div>
                    {!resend ? (
                        <div className='otpVerificationContainer-body-resend'>Resend code in <span>{formatTime()}</span></div>
                    ) : (
                        <div className='otpVerificationContainer-body-resendLink' onClick={() => { }}>
                            {t('Resend Again')}
                        </div>
                    )}
                    {/* <Button text={'Continue'} action={() => {

                    }} /> */}
                </div>
            </div>
            <div className="otpVerificationContainer-footer">
                <Button text="Submit" action={() => { }} />
            </div>
        </div>
    );
};

export default OtpVerificationPage;
