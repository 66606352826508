import React, { useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import showIcon from '../../assets/icons/hide.svg';
import hideIcon from '../../assets/icons/hide.svg';

interface AccountInputBoxProps {
  inputValue: string;
  label?: string;
  text?: string;
  setInputValue: (value: string) => void;
  type?: string;
  error?: string;
  triggerSubmit?: boolean;
  disable?: boolean;
}

const AccountInputBox: React.FC<AccountInputBoxProps> = ({
  inputValue,
  setInputValue,
  label,
  text,
  type = 'text',
  error,
  triggerSubmit = false,
  disable = false
}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <div className={`accountInputBox ${(error && triggerSubmit) && 'error'} `}>
        <div className='accountInputBox-label'>{t(label ?? '')}</div>
        {disable ? (
          <div className='accountInputBox-disableValue'>{inputValue}</div>
        ) : (
          <input type={type === 'password' && visible ? 'text' : type} value={inputValue} onChange={handleChange} placeholder={text} />
        )}
        {type === 'password' && (
          <div className='accountInputBox-eyeIcon' onClick={() => setVisible(!visible)}>
            {visible ? (<img style={{ width: 20, height: 20 }} src={hideIcon} />) : (<img style={{ width: 20, height: 20 }} src={showIcon} />)}
          </div>)}
      </div>
      {(error && triggerSubmit) && (
        <div>
          <p className='error'>{error}</p>
        </div>
      )}
    </>
  );
};

export default AccountInputBox;
