import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../types/dataType';
import AccountInputBox from '../../components/AccountInputBox';
import { useNotification } from '../../contexts/NotificationContext';
import { useLoading } from '../../contexts/LoadingContent';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { useAuth } from '../../contexts/AuthContent';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../services/firebase";


const ForgetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [emailData, setEmailData] = useState<any>({});
  const { showNotification } = useNotification();
  const { showLoading, hideLoading } = useLoading() || {};


  const onchangeData = (text: string, field: string) => {
    const inputData = _.cloneDeep(emailData);
    if (text === '') {
      _.unset(inputData, field);
    } else {
      _.set(inputData, field, text);
    }
    setEmailData(inputData);
  };

  const forgetPassword = async () => {
    setTriggerSubmit(true);
    showLoading?.();
    if (_.isEmpty(errors)) {
      if (emailData?.email) {
        try {
          await sendPasswordResetEmail(auth, emailData?.email);

          showNotification("Password reset email sent! Check your inbox.", 'success');
          navigate('/login');
        } catch (error: any) {
          console.log('Error updating password:', error);
          switch (error.code) {
            case "auth/user-not-found":
              showNotification("Invalid User Email.", 'error');
              break;
          }
        }
      }
    }
    hideLoading?.();
  }

  useEffect(() => {
    console.log('Player Data', emailData)
    const inputData = _.cloneDeep(errors);

    if ((emailData?.email === '' || !emailData?.email)) {
      _.set(inputData, 'email', 'required');
    } else {
      _.unset(inputData, 'email');
    }

    console.log('errors', inputData)
    setErrors(inputData);
  }, [emailData]);

  return (
    <div className='forgetPasswordPage'>
      <Header back title={t('Forget Password')} whiteTheme backAction={() => { navigate(-1) }} />
      <div className='forgetPasswordPage-bodySection'>
        <div className='forgetPasswordPage-bodySection-inputBox'>
          <AccountInputBox
            label={'Email'}
            inputValue={emailData?.email ?? ''}
            text={'Email'}
            setInputValue={(e) => onchangeData(e, 'email')}
            triggerSubmit={triggerSubmit}
            error={errors?.email}
          />
        </div>

      </div>
      <div className='forgetPasswordPage-footerSection'>
        <Button text={`Forget Password`} action={() => {
          forgetPassword();
        }} />
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
