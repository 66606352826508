import { gql } from "@apollo/client";

export const SAVE_ARDDESS = gql`
  mutation CreateUserAddress($input: CreateUserAddressInput!) {
    createUserAddress(input: $input) {
      result {
        _id
        user_id
        is_default
        address_name
        address_details
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;