import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../types/dataType';
import AccountInputBox from '../../components/AccountInputBox';
import { useNotification } from '../../contexts/NotificationContext';
import { useLoading } from '../../contexts/LoadingContent';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { useAuth } from '../../contexts/AuthContent';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth } from "../../services/firebase";


const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [passwordData, setPasswordData] = useState<any>({});
  const { showNotification } = useNotification();
  const { showLoading, hideLoading } = useLoading() || {};


  const onchangeData = (text: string, field: string) => {
    const inputData = _.cloneDeep(passwordData);
    if (text === '') {
      _.unset(inputData, field);
    } else {
      _.set(inputData, field, text);
    }
    setPasswordData(inputData);
  };

  const resetPassword = async () => {
    setTriggerSubmit(true);
    showLoading?.();
    if (_.isEmpty(errors)) {
      const user = auth.currentUser;
      if (user && user.email) {
        const credential = EmailAuthProvider.credential(user.email, passwordData?.old_password);
        try {
          await reauthenticateWithCredential(user, credential);
          await updatePassword(user, passwordData?.confirm_password);
          showNotification("Password successfully updated!", 'success');
        } catch (error: any) {
          console.log('Error updating password:', error);
          switch (error.code) {
            case "auth/wrong-password":
              showNotification("Wrong password.", 'error');
              break;
          }
          // setMessage(error.message);
        }
      }
    }
    hideLoading?.();
  }

  useEffect(() => {
    console.log('Player Data', passwordData)
    const inputData = _.cloneDeep(errors);

    if ((passwordData?.password === '' || !passwordData?.password)) {
      _.set(inputData, 'password', 'required');
    } else {
      _.unset(inputData, 'password');
    }
    if ((passwordData?.old_password === '' || !passwordData?.old_password)) {
      _.set(inputData, 'old_password', 'required');
    } else {
      _.unset(inputData, 'old_password');
    }
    if ((passwordData?.confirm_password === '' || !passwordData?.confirm_password)) {
      _.set(inputData, 'confirm_password', 'required');
    } else if (passwordData?.confirm_password !== passwordData?.password) {
      _.set(inputData, 'confirm_password', 'Password does not match');
    } else {
      _.unset(inputData, 'confirm_password');
    }
    console.log('errors', inputData)
    setErrors(inputData);
  }, [passwordData]);

  return (
    <div className='resetPasswordPage'>
      <Header back title={t('Change Password')} whiteTheme backAction={() => { navigate(-1) }} />
      <div className='resetPasswordPage-bodySection'>
        <div className='resetPasswordPage-bodySection-inputBox'>
          <AccountInputBox
            type={'password'}
            label={'Old Password'}
            inputValue={passwordData?.old_password ?? ''}
            text={'Old Password'}
            setInputValue={(e) => onchangeData(e, 'old_password')}
            error={errors?.old_password}
            triggerSubmit={triggerSubmit}
          />
        </div>
        <div className='resetPasswordPage-bodySection-inputBox'>
          <AccountInputBox
            type={'password'}
            label={'New Password'}
            inputValue={passwordData?.password ?? ''}
            text={'New Password'}
            setInputValue={(e) => onchangeData(e, 'password')}
            error={errors?.password}
            triggerSubmit={triggerSubmit}
          />
        </div>
        <div className='resetPasswordPage-bodySection-inputBox'>
          <AccountInputBox
            type={'password'}
            label={'Confirm New Password'}
            inputValue={passwordData?.confirm_password ?? ''}
            text={'Confirm New Password'}
            setInputValue={(e) => onchangeData(e, 'confirm_password')}
            error={errors?.confirm_password}
            triggerSubmit={triggerSubmit}
          />
        </div>
      </div>
      <div className='resetPasswordPage-footerSection'>
        <Button text={`Edit Password`} action={() => {
          resetPassword();
        }} />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
