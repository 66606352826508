import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import LinkPath from '../../components/LinkPath';
import config from '../../lib/config';

const LandingPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const latest_merOrderNo = localStorage.getItem('latest_merOrderNo');
        if (latest_merOrderNo) {
            navigate(`/paymentStatus?merOrderNo=${latest_merOrderNo}`);
        }
    }, []);

    return (
        <div className='landingContainer'>
            <img src={require('../../assets/images/landingBackground.png')} />
            <div className='landingContainer-bottomSection'>
                <div className='landingContainer-bottomSection-top'>
                    <img src={require('../../assets/images/logo.png')} />
                    <div className='landingContainer-bottomSection-top-text'>BeReach</div>
                </div>
                <div className='landingContainer-bottomSection-center mt-3'>
                    <div className='landingContainer-bottomSection-center-firstText'>
                        {t('Dine')}
                    </div>
                    <div className='landingContainer-bottomSection-center-secondText'>
                        {t('Smarter')}
                    </div>
                </div>
                <div className='mt-5'>
                    <Button text={'Get Started'} action={() => { navigate('/login') }} />
                </div>
                <div className='landingContainer-bottomSection-link my-3'>
                    <LinkPath text={'Continue as guest'} action={() => navigate('/home')} />
                </div>
                <div className='landingContainer-bottomSection-version'>
                    {t('version')} {config?.VERSION_NO}
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
