import React from 'react';
import './index.scss';
import useFooterVisibility from '../../hooks/useFooterVisibility';
import Footer from '../Footer';


interface ContainerProps {
    children: React.ReactNode;
}

const BodyContainer: React.FC<ContainerProps> = ({ children }) => {
    const footerVisible = useFooterVisibility();

    return <div className="bodyContainer">
        <div className="bodyContainerInner">
            {children}
            <Footer display={footerVisible} />
        </div>
    </div>;
};

export default BodyContainer;
