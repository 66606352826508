import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB-SyuZk1-f45gjA_fDyEx1rjHBPsiTZr4",
  authDomain: "bereach-co.firebaseapp.com",
  projectId: "bereach-co",
  storageBucket: "bereach-co.firebasestorage.app",
  messagingSenderId: "1045887168780",
  appId: "1:1045887168780:web:fc0338c18e4e54acbdd929",
  measurementId: "G-FYR71P7KEK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Auth and Provider
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");
export const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope("email");
facebookProvider.addScope("public_profile");
