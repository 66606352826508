import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Store } from '../../types/dataType';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ItemContainerProps {
    item: Store;
}
// Functional component with props type
const ItemContainer: React.FC<ItemContainerProps> = ({
    item
}) => {

    const { t } = useTranslation();

    return (

        <div className='itemContainer'>
            <div className='itemContainer-inner'>
                {!item?.isOpen && (
                    <div className='close'>
                        <div className='close-status'>
                            <div className='close-status-label'>
                                {t('Close')}
                            </div>
                        </div>
                    </div>
                )}
                <div className='itemContainer-inner-img'>
                    <LazyLoadImage
                        effect="opacity"
                        height={'100%'}
                        src={item?.logo_image || ''}
                        width={'auto'} />
                </div>
                <div className='itemContainer-inner-name'>
                    {t(`${item?.display_name}`)}
                </div>
                <div className='itemContainer-inner-bottomSection'>
                    {_.map(item?.tag, (tag, index) => (
                        <div key={index} className='itemContainer-inner-bottomSection-tag'>
                            {tag}
                        </div>
                    ))}
                    {_.map(item?.type, (type, index) => (
                        <div key={index} className='itemContainer-inner-bottomSection-type'>
                            {type}
                        </div>
                    ))}
                    {/* {item?.minSpend !== undefined && item?.minSpend !== null && item.minSpend !== 0 && (
                <div className='itemContainer-inner-bottomSection-minSpend'>
                    {t('Min.')} RM{item?.minSpend.toFixed(2)}
                </div>
                )} */}
                </div>
            </div>
        </div>
    );
};

export default ItemContainer;
